import React, { useEffect, useState } from 'react'
import { viewOrderDetails } from '../../services/order'
import daburLogo from "../../assets/img/dabur-logo.png"
import { dateTimeFormatter } from '../../services/helper'
import { toast } from 'sonner'
const color = "light"
const orderDatas = {}

const data = {
    "id": 661651,
    "shopify_id": "18935",
    "email": "raja.r.6teen@gmail.com",
    "name": "000020981",
    "created_at": "2024-08-29T14:40:14.873Z",
    "updated_at": "2024-08-29T14:40:14.873Z",
    "closed_at": "",
    "note": "",
    "note_attributes": "",
    "token": "",
    "gateway": "razorpay",
    "total_price": "755.0000",
    "subtotal_price": "695.0000",
    "total_weight": "1657.0000",
    "total_tax": "0.0000",
    "tax_included": "NA",
    "currency": "INR",
    "financial_status": "NA",
    "confirmed": "",
    "total_discount": "0.0000",
    "cart_tocken": "",
    "buyer_accepts_marketing": "NA",
    "cancelled_at": "",
    "cancel_reason": "",
    "total_price_usd": "",
    "checkout_token": "NA",
    "user_id": "1532351",
    "processed_at": "",
    "device_id": "",
    "app_id": "",
    "browser_ip": "3.108.3.235",
    "order_number": "000020981",
    "payment_gateway": "razorpay",
    "processing_method": "",
    "checkout_id": "NA",
    "source_name": "",
    "fullfilment_status": "",
    "tax_price": "0.0000",
    "tax_rate": "NA",
    "tax_title": "NA",
    "currency1": "",
    "tax_price2": "",
    "tax_rate2": "",
    "tax_tiltle2": "",
    "currency2": "",
    "order_status": "delivered",
    "contact_email": "",
    "tags": "",
    "billing_fname": "Raja",
    "billing_address1": "Sigma Tech Park",
    "phone": "8056289595",
    "billing_city": "Bengaluru",
    "billing_zip": "560005",
    "billing_province": "Karnataka",
    "billing_lname": "Ravi",
    "billing_address2": "Varthur Kodi , 43, Delta Block",
    "company": "",
    "latitude": "",
    "longitude": "",
    "billing_name": "Raja Ravi",
    "billing_country_code": "",
    "billing_privince_code": "",
    "shipping_fname": "Raja",
    "shipping_address1": "Sigma Tech Park",
    "phone1": "8056289595",
    "shipping_city": "Bengaluru",
    "shipping_zip": "560005",
    "shipping_province": "Karnataka",
    "shipping_country": "",
    "shipping_lname": "Ravi",
    "shipping_address2": "Varthur Kodi , 43, Delta Block",
    "lm_partner": "Borzo",
    "lm_provider": null,
    "loading_status": null,
    "int_updated_datetime": null,
    "shipping_company": "",
    "shipping_lat": "12.9581866",
    "shipping_long": "77.743893",
    "shipping_name": "",
    "shipping_country_code": "",
    "shipping_province_code": "",
    "paid_at": "",
    "total_shipping": "50.0000",
    "platform_fee": "10.0000",
    "low_order_fee": "0.0000",
    "discount_code": "NA",
    "shipping_type": "customshipping_customshipping",
    "payment_ref": "",
    "refund": "",
    "vendor": "",
    "risk_level": "",
    "source": "NA",
    "receipt_no": "",
    "datetime": "2024-08-29T14:40:14.000Z",
    "cust_id": "",
    "invoice_date": "2024-09-30",
    "delivery_date": "2024-08-30 19:40:34",
    "cust_delivery_date": "2024-08-29T00:00:00.000Z",
    "cust_delivery_slot": "express",
    "cust_delivery_slot_id": "",
    "awb_no": "",
    "sfx_status": "out_for_delivery",
    "sfx_delivery_date": "",
    "sfx_datetime": "2024-08-29T09:10:14.000Z",
    "binary_update_date": "",
    "order_update_date": "2024-08-29T19:33:46.000Z",
    "ttt_invoice": "AwABBAACX1wI",
    "client_order_id": "",
    "whizz_awb_no": "",
    "gift_card_amt": null,
    "razor_pay_amt": null,
    "eligible_cashback": "NA",
    "payment_details": "pay_Oqeyf1SgvGJnlr",
    "final_gift_card_amt_paid": "0.0000",
    "final_razorpay_amt_paid": "755.0000",
    "payment_id": null,
    "order_source": "Magento",
    "is_hotel_order": "0",
    "is_hotel_order_pushed": "0",
    "source_code": "DS1",
    "is_order_pushed": "0",
    "delivery_tip_amount": "0.00",
    "customer_mobileno": "",
    "customer_alternate_mobileno": "",
    "b_mobileno": "8056289595",
    "b_alt_mobileno": "",
    "s_mobileno": "8056289595",
    "s_alt_mobileno": "",
    "delivery_team": "TTT_DS",
    "orderDetails": [
        {
            "id": 3618595,
            "shopify_id": "18935",
            "order_no": "000020981",
            "cust_id": "1532351",
            "item_id": "ASCHILLIPOWDER100",
            "item_no": "ASCHILLIPOWDER100",
            "variant_id": "ASCHILLIPOWDER100",
            "title": "Aashirvaad Chilli Powder 100g",
            "quantity": "1.0000",
            "sku": "ASCHILLIPOWDER100",
            "variant_title": "",
            "vendor": "",
            "fulfillment_service": "",
            "product_id": "",
            "require_shipping": "0",
            "taxable": "",
            "gift_ard": "",
            "name": "",
            "variant_inventory_management": "",
            "proprties": "",
            "product_exists": "",
            "fulfillable_quantity": "",
            "price": "36.0000",
            "discount": "0.0000",
            "product_type": "",
            "discount_type": "",
            "fulfillment_status": "",
            "qty_returned": "",
            "reason": "",
            "tax_title": "",
            "tax_price": "",
            "tax_rate": "",
            "tax_title2": "",
            "tax_price2": "",
            "tax_rate2": "",
            "currency_code": "",
            "shipped_status": "",
            "shipped_qty": "NA",
            "shipped_date": "",
            "hsn_code": "",
            "tax_per": "0.0000",
            "qty_not_received": "",
            "mrp_diff": "",
            "datetime": "2024-08-29T14:40:14.000Z"
        },
        {
            "id": 3618594,
            "shopify_id": "18935",
            "order_no": "000020981",
            "cust_id": "1532351",
            "item_id": "PANEERMALAI285",
            "item_no": "PANEERMALAI285",
            "variant_id": "PANEERMALAI285",
            "title": "Kitchens of India Ready to Eat Paneer Malai 285g",
            "quantity": "1.0000",
            "sku": "PANEERMALAI285",
            "variant_title": "",
            "vendor": "",
            "fulfillment_service": "",
            "product_id": "",
            "require_shipping": "0",
            "taxable": "",
            "gift_ard": "",
            "name": "",
            "variant_inventory_management": "",
            "proprties": "",
            "product_exists": "",
            "fulfillable_quantity": "",
            "price": "160.0000",
            "discount": "0.0000",
            "product_type": "",
            "discount_type": "",
            "fulfillment_status": "",
            "qty_returned": "",
            "reason": "",
            "tax_title": "",
            "tax_price": "",
            "tax_rate": "",
            "tax_title2": "",
            "tax_price2": "",
            "tax_rate2": "",
            "currency_code": "",
            "shipped_status": "",
            "shipped_qty": "NA",
            "shipped_date": "",
            "hsn_code": "",
            "tax_per": "0.0000",
            "qty_not_received": "",
            "mrp_diff": "",
            "datetime": "2024-08-29T14:40:14.000Z"
        },
        {
            "id": 3618593,
            "shopify_id": "18935",
            "order_no": "000020981",
            "cust_id": "1532351",
            "item_id": "SVWP72",
            "item_no": "SVWP72",
            "variant_id": "SVWP72",
            "title": "Savlon Germ Protection Wipes - 72s Pack",
            "quantity": "1.0000",
            "sku": "SVWP72",
            "variant_title": "",
            "vendor": "",
            "fulfillment_service": "",
            "product_id": "",
            "require_shipping": "0",
            "taxable": "",
            "gift_ard": "",
            "name": "",
            "variant_inventory_management": "",
            "proprties": "",
            "product_exists": "",
            "fulfillable_quantity": "",
            "price": "189.0000",
            "discount": "0.0000",
            "product_type": "",
            "discount_type": "",
            "fulfillment_status": "",
            "qty_returned": "",
            "reason": "",
            "tax_title": "",
            "tax_price": "",
            "tax_rate": "",
            "tax_title2": "",
            "tax_price2": "",
            "tax_rate2": "",
            "currency_code": "",
            "shipped_status": "",
            "shipped_qty": "NA",
            "shipped_date": "",
            "hsn_code": "",
            "tax_per": "0.0000",
            "qty_not_received": "",
            "mrp_diff": "",
            "datetime": "2024-08-29T14:40:14.000Z"
        },
        {
            "id": 3618592,
            "shopify_id": "18935",
            "order_no": "000020981",
            "cust_id": "1532351",
            "item_id": "ITCMC RED BGRVY200",
            "item_no": "ITCMC RED BGRVY200",
            "variant_id": "ITCMC RED BGRVY200",
            "title": "ITC Master Chef Tomato Makhani All-Purpose Gravy 200g",
            "quantity": "1.0000",
            "sku": "ITCMC RED BGRVY200",
            "variant_title": "",
            "vendor": "",
            "fulfillment_service": "",
            "product_id": "",
            "require_shipping": "0",
            "taxable": "",
            "gift_ard": "",
            "name": "",
            "variant_inventory_management": "",
            "proprties": "",
            "product_exists": "",
            "fulfillable_quantity": "",
            "price": "100.0000",
            "discount": "0.0000",
            "product_type": "",
            "discount_type": "",
            "fulfillment_status": "",
            "qty_returned": "",
            "reason": "",
            "tax_title": "",
            "tax_price": "",
            "tax_rate": "",
            "tax_title2": "",
            "tax_price2": "",
            "tax_rate2": "",
            "currency_code": "",
            "shipped_status": "",
            "shipped_qty": "NA",
            "shipped_date": "",
            "hsn_code": "",
            "tax_per": "0.0000",
            "qty_not_received": "",
            "mrp_diff": "",
            "datetime": "2024-08-29T14:40:14.000Z"
        },
        {
            "id": 3618591,
            "shopify_id": "18935",
            "order_no": "000020981",
            "cust_id": "1532351",
            "item_id": "ASNSFORG TURDAL1KG",
            "item_no": "ASNSFORG TURDAL1KG",
            "variant_id": "ASNSFORG TURDAL1KG",
            "title": "Aashirvaad Organic Tur/Arhar Dal 1kg",
            "quantity": "1.0000",
            "sku": "ASNSFORG TURDAL1KG",
            "variant_title": "",
            "vendor": "",
            "fulfillment_service": "",
            "product_id": "",
            "require_shipping": "0",
            "taxable": "",
            "gift_ard": "",
            "name": "",
            "variant_inventory_management": "",
            "proprties": "",
            "product_exists": "",
            "fulfillable_quantity": "",
            "price": "210.0000",
            "discount": "0.0000",
            "product_type": "",
            "discount_type": "",
            "fulfillment_status": "",
            "qty_returned": "",
            "reason": "",
            "tax_title": "",
            "tax_price": "",
            "tax_rate": "",
            "tax_title2": "",
            "tax_price2": "",
            "tax_rate2": "",
            "currency_code": "",
            "shipped_status": "",
            "shipped_qty": "NA",
            "shipped_date": "",
            "hsn_code": "",
            "tax_per": "0.0000",
            "qty_not_received": "",
            "mrp_diff": "",
            "datetime": "2024-08-29T14:40:14.000Z"
        }
    ]
}

function ViewOrderDetailModal({ closeModal, orderId }) {
    const [orderData, setOrderData] = useState({});

    useEffect(() => {
        (async () => {
            const response = await viewOrderDetails({order_number: orderId});
            const { status, data } = response || {};
            if (status === 200) {
                setOrderData(data?.data);
            }else{
                toast.error("Something Went Wrong");
                closeModal(false)
            }
        })()
    }, [])

    useEffect(() => {
      console.log(orderData);
      
    }, [orderData])
    

    return (
        <div className="relative p-2 w-full max-h-[85vh] overflow-auto">
            <div className="relative bg-white rounded-lg shadow">
                <button type="button" onClick={() => closeModal(false)} className="absolute top-0 right-0 end-2.5 z-100 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                </button>
                <div className='rounded-lg w-full shadow-xl bg-white p-3'>
                    <div className="flex pl-3 justify-between items-center">
                        <h1><b>Order No.:</b> #{orderId}</h1>
                        <img src={daburLogo} className='h-16' alt="" />
                    </div>
                    <div className="bg-green-100 rounded-md shadow-xl text-sm grid md:grid-cols-2 grid-cols-1 p-4 gap-3">
                        <h1><b>Billing Name :</b> {orderData?.billing_fname+" "+orderData?.billing_lname || ""}</h1>
                        <h1><b>Email :</b> {orderData?.email || ""}</h1>
                        <h1><b>Phone No. :</b> {orderData?.phone || ""}</h1>
                        <h1><b>Alternate No. :</b> {orderData?.phone1 || ""}</h1>
                        <h1><b>Order Id. :</b> {orderData?.id || ""}</h1>
                        <h1><b>Order Status :</b> {orderData?.order_status || "Not Available"}</h1>
                        <h1><b>Order Date :</b> {dateTimeFormatter(orderData?.datetime) || ""}</h1>
                        <h1><b>Delivery Date :</b> {dateTimeFormatter(orderData?.cust_delivery_date) || ""}</h1>
                    </div>
                    <div
                        className={
                            "relative flex flex-col min-w-0 mt-3 break-words w-full mb-6 shadow-lg rounded " +
                            (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                        }
                    >
                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="flex flex-wrap items-center">
                                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3
                                        className={
                                            "font-semibold text-lg " +
                                            (color === "light" ? "text-blueGray-700" : "text-white")
                                        }
                                    >
                                        Items List
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="block w-full overflow-x-auto">
                            {/* Projects table */}
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            S.No.
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Product Name
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Quantity
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Unit Price
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Discount
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Shipped Quantity
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >Date & Time</th>
                                
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderData && orderData?.orderDetails?.map((item, index) => (
                                        <tr>
                                            <td className="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {index + 1}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle font-bold border-l-0 border-r-0 text-xs p-4">
                                                {item?.title}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {(Number(item?.quantity)).toFixed(0)}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {(Number(item?.price)).toFixed(2)}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {(Number(item?.discount)).toFixed(2)}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item?.shipped_qty}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {dateTimeFormatter(item?.shipped_date)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewOrderDetailModal