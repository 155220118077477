import React from "react";
import {Route, Routes } from "react-router-dom";
import Login from "../views/auth/Login";
import LoginX from "../views/auth/LoginX";
import FooterSmall from "../components/Footers/FooterSmall";

export default function Auth() {
  return (
    <>
      {/* <Navbar transparent /> */}
      <main>
        <section className="relative w-full h-full py-20 min-h-screen">
        <div
            className="absolute top-0 w-full h-full dabur-bg1 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("../assets/img/register_bg_2.png").default + ")",
            }}
          ></div>
          <Routes>
            <Route path="login" element={<Login/>} />
            {/* <Route path="/auth/register" element={<Register/>} />*/}
          </Routes>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
