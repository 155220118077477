import React, { useState } from 'react'
import { PulseLoader } from 'react-spinners';
import { loginOtpVerification } from '../../services/auth';
import { useNavigate } from 'react-router-dom';

const VerifyOtpModal = ({ closeModal, email }) => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [verifyError, setVerifyError] = useState("")

    const handleOtpSubmit = async () => {
        setLoader(true);
        setIsSubmit(true);
        if (otp !== "") {
            const submitOtpResponse = await loginOtpVerification({ email: email, otp });
            const { status, data } = submitOtpResponse;
            if (status === 200) {
                localStorage.setItem("token", data?.token)
                localStorage.setItem("role", data?.role)
                localStorage.setItem("userDetails", JSON.stringify(data?.data))
                localStorage.setItem("session", "not_expired")
                navigate("/admin")
            } else {
                setVerifyError(data.message)
            }
            setLoader(false);
        }
    }

    return (
        <div className="relative p-2 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <h3 className="text-xl mt-4 font-semibold text-gray-900">
                        Verification
                    </h3>
                </div>
                <button type="button" onClick={() => closeModal(false)} className="absolute top-0 right-0 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                </button>
                <div className="p-4 md:p-5 space-y-4">
                    <div>
                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900">Please enter the One-Time Password (OTP) sent to your registered email address. The OTP is valid for the next 10 minutes.</label>
                        {isSubmit && otp === "" && (
                            <h4 className='text-center text-red-700 text-sm my-2'>
                                Please Enter Otp
                            </h4>
                        )}
                        <input type="text" name="otp" value={otp} onChange={(e) => { setVerifyError(""); setOtp(e.target.value) }} id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Enter OTP here.." required />
                    </div>
                    <div>
                        <h1 className='text-center text-xs text-red-600'>{verifyError}</h1>
                    </div>
                    <button onClick={handleOtpSubmit} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{loader ?
                        <PulseLoader
                            color="#fff"
                            size={9}
                            speedMultiplier={1}
                        />
                        : "Continue"}</button>
                </div>
            </div>
        </div>
    )
}

export default VerifyOtpModal;