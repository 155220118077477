import { axiosCall } from "./apiRequest";
import { baseUrl } from "./helper";

export const getOrderOverview = async (userData) => {
  try {
    const token = localStorage.getItem("token")
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + "order/order-overview", "get", "", header
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrderList = async (userData) => {
  try {
    const token = localStorage.getItem("token")
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + "order/customer/order-list", "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrderDetails = async (userData) => {
  try {
    const token = localStorage.getItem("token")
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + "order/order-view", "get", "", header, userData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadInvoice = async (userData) => {
  try {
    const token = localStorage.getItem("token")
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `invoice/generate/${userData}`, "get", "", header, ""
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const findRefundInvoice = async (userData) => {
  try {
    const token = localStorage.getItem("token")
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `invoice/find-refund-invoice`, "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const updateRefundStatus = async (userData) => {
  try {
    const token = localStorage.getItem("token")
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `invoice/update-refund-status`, "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const getAgentList = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `invoice/get-user-list`, "get", "", header
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const approveRefund = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `invoice/refund-approve`, "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const orderNotPlacedList = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `invoice/find-not-placed-order`, "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const salesReportApi = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `order/sales-report`, "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const newSalesReportApi = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `order/new-sales-report`, 'post', userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const shipNotCompleteListApi = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `order/ship-not-complete-list`, "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const updateUnShippedItemsReason = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `order/update-delay-reason`, "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const refundReportApi = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `order/refunds-report`, "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const ticketingOverviewApi = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `order/ticket-overview`, "get", "", header
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const crmCallsOverviewApi = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + `order/crms-call-overview`, "get", "", header
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const listOfOrder = async(userData) => {
  try{
    return await axiosCall(baseUrl + `order/list-of-order`, "post" , userData);
  }catch(error){
     throw(error)
  }
}
export const pushOrderToDTDCorEComm = async(userData) => {
  try{
    return await axiosCall(baseUrl + `order/push`, "post" , userData);
  }catch(error){
     throw(error)
  }
}
export const viewOrderDetails = async(userData) => {
  try{
    return await axiosCall(baseUrl + `order/detail`, "get" , "","",userData);
  }catch(error){
     throw(error)
  }
}

export const getOrderTrackingData = async(userData) => {
  try{
    return await axiosCall(baseUrl + `order/order-detail`, "post" , userData);
  }catch(error){
     throw(error)
  }
}

