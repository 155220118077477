import { axiosCall } from "./apiRequest";
import { baseUrl } from "./helper";

export const stellerLogin = async (userData) => {
  try {
    const response = await axiosCall(baseUrl + "user/login", "post", userData
    );
    return response;
  } catch (error) {
    throw error;
  }
};


export const loginOtpVerification = async (userData) => {
  try {
    const response = await axiosCall(baseUrl + "user/verify-otp", "post", userData
    );
    return response;
  } catch (error) {
    throw error;
  }
};


export const changeLoginPassword = async (userData) => {
  try {
    const response = await axiosCall(baseUrl + "user/verify-otp", "post", userData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const verifyToken = async () => {
  try {
    const token = localStorage.getItem("token")
    const response = await axiosCall(baseUrl + "user/check-token", "post", {token}
    );
    return response;
  } catch (error) {
    throw error;
  }
}


export const changeUserPassword = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + "user/reset-password", "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const initiateTheRefund = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const header = { Authorization: `Bearer ${token}` };
    const response = await axiosCall(baseUrl + "invoice/refund-initiate", "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
};

