import React from 'react'
import { ClipLoader } from 'react-spinners'

const Loader = ({height}) => {
    const full = true;
    return (
        <div>
            <div className={`${full? 'top-0': 'md:top-[58px] top-[95px]'}`} style={{
                width: "100%",
                height: `${height ? height : '100vh'}`,
                // position: "fixed",
                background: `${full ? 'rgba(255,255,255,0.35)' : "rgba(255,255,255,0.5)"}`,
                display: "flex",
                zIndex: "999",
                // top: "4rem",
                left: "0",
                justifyContent: "center",
                alignItems: "center"
            }}>
                    <ClipLoader
                    color="#1B5C29"
                    size={60}
                    speedMultiplier={1}
                    cssOverride={
                        {borderWidth: "4px"}
                    }
                />
                
            </div>
        </div>
    )
}

export default Loader