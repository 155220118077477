import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";
import VerifyOtpModal from "../../components/Modals/VerifyOtpModal";
// import ChangePasswordModal from "components/Modals/ChangePasswordModal";
import { regularExpression } from "../../services/helper";
import { stellerLogin, loginOtpVerification } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { toast } from "sonner";
Modal.setAppElement("#root");

const validationSchema = Yup.object().shape({
  email: Yup.string('Enter Email')
    .required('Email Is Required')
    .matches(regularExpression.EMAIL_REGEX, 'Please Enter Valid Email Id'),
  password: Yup.string()
    .required("Please Enter Password"),
  // legalDeclaration: Yup.boolean()
  //     .test(
  //         "is-true",
  //         "Please accept the legal declaration to proceed.",
  //         (value) => value === true
  //     ) // Custom test for boolean field
  //     .required()
});
const initialState = {
  email: "",
  password: ""
};

export default function Login() {
  const navigate = useNavigate()
  const [verifyOtpModal, setVerifyOtpModal] = useState(false);
  const [otpExpiryTime, setOtpExpiryTime] = useState(null);
  const [loginLoader, setLoginLoader] = useState(false);
  const session = localStorage.getItem("session");
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
  });
  const token = localStorage.getItem("token")

  const handleInputLogin = (e) => {
    const { value, name } = e?.target;
    formik?.setFieldValue(name, value);
  }

  const handleLoginSubmit = async () => {
    setLoginLoader(true);
    const payload = {
      email: formik?.values?.email,
      password: formik?.values?.password
    }
    if (formik?.isValid && formik?.dirty) {
      const loginResponse = await stellerLogin(payload);
      console.log(loginResponse);
      const { status, data } = loginResponse || {};
      if (status === 200) {
        setOtpExpiryTime(data?.time);
        setLoginLoader(false);
        setVerifyOtpModal(true);
      }else{
        setLoginLoader(false);
        toast.error(data?.message || "Something went wrong, try again later");
      }
    }else{
      setLoginLoader(false);
      toast.error("Please Provide valid Credentials")
    }
  }

  const handleOtp = async (otp) => {
    const submitOtpResponse = await loginOtpVerification({ email: formik?.values?.email, otp });
    const { status, data } = submitOtpResponse;
    if (status === 200) {
      localStorage.setItem("token", data?.token)
      localStorage.setItem("role", data?.role)
      navigate("/admin")
    }
  }

  useEffect(() => {
    if (token && token !== "" && session !== "expired") {
      navigate("/admin")
    }
  }, [])


  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="flex justify-center">
                  <img src={`/dabur-logo.png`} className="h-20" />
                </div>
                <div className="text-center mb-3 mt-2">
                  <h6 className="dabur-text3 text-sm font-bold">
                    STELLER LOGIN
                  </h6>
                </div>
                {/* <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/github.svg").default}
                    />
                    Github
                  </button>
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg").default}
                    />
                    Google
                  </button>
                </div> */}
                <hr className="mt-4 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                {/* <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div> */}
                <form onSubmit={formik.handleSubmit}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase dabur-text3 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-[#154E21] w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      value={formik?.values?.email}
                      onChange={handleInputLogin}
                    />
                    <h4 className="text-xs text-red-600 mt-1">
                      {formik?.errors?.email &&
                        formik?.touched?.email &&
                        formik?.errors?.email}
                    </h4>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase dabur-text3 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={formik?.values?.password}
                      onChange={handleInputLogin}
                    />
                    <h4 className="text-xs text-red-600 mt-1">
                      {formik?.errors?.password &&
                        formik?.touched?.password &&
                        formik?.errors?.password}
                    </h4>
                  </div>
                  {/* <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label>
                  </div> */}

                  <div className="text-center mt-6">
                    {loginLoader && (
                      <BarLoader
                      color="#F4711F"
                      speedMultiplier={1.4}
                      cssOverride={
                        { marginBottom: "6px", width: "100%" }
                      }
                      />
                    )}
                    <button
                      className="dabur-bg3 hover:bg-[#11421b] text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                      disabled={loginLoader}
                      onClick={() => {if(!loginLoader) handleLoginSubmit()}}
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        <Modal
          className="modal-menu m-auto"
          isOpen={verifyOtpModal}
          onRequestClose={() => setVerifyOtpModal(false)}
          contentLabel="XYZ Component Modal"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)'
            },
          }}
        >
          <VerifyOtpModal handleOtp={handleOtp} closeModal={setVerifyOtpModal} email={formik.values.email} />
        </Modal>

      </div>
    </>
  );
}
