import React, { Suspense } from 'react'
import SidebarOrderList from '../components/Sidebar/SidebarOrderList'
import FooterAdmin from '../components/Footers/FooterAdmin'
import { Route, Routes } from 'react-router-dom'
import HeaderStats from '../components/Headers/HeaderStats'
import Loader from '../components/Loader/Loader'
import AdminNavbar from "../components/Navbars/AdminNavbar"
import OrderListingPage from '../views/OrderListingPage'


const OrderLists = () => {
  return (
    <>
      <SidebarOrderList />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <HeaderStats />  {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full -m-32">
        <Suspense fallback={<Loader />}>
          <OrderListingPage/>
          <FooterAdmin />
          </Suspense>
        </div>
      </div>
    </>
  )
}

export default OrderLists