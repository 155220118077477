import React, { useEffect, useRef, useState } from 'react'
import daburLogo from "../assets/img/dabur-logo.png"
import { baseUrl, dateTimeFormatter } from '../services/helper'
import { downloadPushedOrders, listOfOrder, pushOrderToDTDCorEComm } from '../services/order'
import { toast } from 'sonner'
import ViewOrderDetailModal from '../components/Modals/ViewOrderDetailModal'
import Modal from "react-modal";
import { ClipLoader } from 'react-spinners'
Modal.setAppElement("#root");

const OrderListingPage = () => {
  const [itemsLists, setItemsList] = useState([]);
  const [totalItems, setTotalItems] = useState("0");
  const [pageNo, setPageNo] = useState(0);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [orderId, setOrderId] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pushLoader, setPushLoader] = useState("");
  const [pushLoaderId, setPushLoaderId] = useState("");

  const onPagination = (type) => {
    if (type === "next") {
      if (pageNo !== Math.trunc(+totalItems / 10)) setPageNo((prev) => prev + 1);
    }
    if (type === "prev") {
      if (pageNo + 1 !== 1) setPageNo((prev) => prev - 1);
    }
    if (type === "set") {
      if (pageNumber < +totalItems / 10 && +pageNumber > 0) {
        setPageNo(+pageNumber - 1);
      } else {
        setPageNo(0)
      }
    }
    if (type === "last") {
      setPageNo(parseInt(+totalItems / 10))
    }
    if (type === "first") {
      setPageNo(parseInt(0))
    }
  };

  useEffect(() => {
    (async () => {
      const scrollid = document.getElementById('Itemslist');
      const response = await listOfOrder({ page_number: pageNo + 1 + "" })
      console.log(response.data);
      const { orderData } = response?.data || {};
      if (orderData) {
        setItemsList(orderData?.data)
        setTotalItems(orderData?.total)
        setCurrentPage(orderData?.page_number)
        setPageNumber(orderData?.page_number)
        if (scrollid) {
          scrollid.scrollIntoView({ behavior: 'smooth' });
        }
      }
    })()
  }, [pageNo]);

  const onViewHandle = (id) => {
    setOrderId(id);
    setOpenViewModal(true);
  }

  const handlePush = async (type, orderNumber, id) => {
    setPushLoader(type);
    setPushLoaderId(id)
    const res = await pushOrderToDTDCorEComm({ order_number: orderNumber, type });
    const { status, data } = res || {};
    if (status === 200) {
      toast.success(data.message);
      setItemsList((prev) => (prev.map((order) =>
        order.id === id
          ? { ...order, is_order_pushed: "1", lm_partner: type }
          : order
      )))
    } else {
      toast.error(status?.message || "Something Went Wrong")
    }
    setPushLoaderId("")
    setPushLoader("");
  }

  const color = "light"
  return (
    <>
      <div className="flex">
        <div className='py-4 z-50 w-full'>
          <div className='rounded-lg w-full shadow-xl bg-white p-3'>
            <div
              className={
                "relative flex flex-col min-w-0 mt-3 break-words w-full mb-6 shadow-lg rounded " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 border-0">
                <div className="flex flex-wrap items-center mb-4">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        "font-semibold text-lg " +
                        (color === "light" ? "text-blueGray-700" : "text-white")
                      }
                    >
                      Orders List
                    </h3>
                  </div>
                  <button onClick={(e) => window.open(`${baseUrl}order/export`, '_blank', 'noopener,noreferrer')} title='Download Pushed Orders' className='bg-[#475569] text-white font-bold text-sm px-3 py-1 rounded-md'>
                    Download
                    <i className="fas fa-download ml-1 text-white"></i>
                  </button>
                </div>
              </div>
              <div className="block w-full overflow-x-auto" id='Itemslist'>
                {/* Projects table */}
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        S.No.
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Order No.
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Order Amount
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Order Date
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Name
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Customer Address
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >Pincode</th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >City</th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >State</th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Status
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        View
                      </th>
                      <th
                        className={
                          " sticky right-0 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Action
                      </th>
                      {/* <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      ></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {itemsLists?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="border-t-0 px-6 border-l-0 border-r-0 text-xs p-4">
                          {(index + 1) + ((currentPage - 1) * 10)}
                        </td>
                        <td className="border-t-0 px-6 align-middle font-bold border-l-0 whitespace-nowrap border-r-0 text-xs p-4">
                          {item?.order_number}
                        </td>
                        <td className="border-t-0 px-6 align-middle font-bold border-l-0 whitespace-nowrap border-r-0 text-xs p-4">
                          ₹ {Number(item?.total_price).toFixed(2)}
                        </td>
                        <td className="border-t-0 px-6 align-middle font-bold border-l-0 whitespace-nowrap border-r-0 text-xs p-4">
                          {dateTimeFormatter(item?.datetime)}
                        </td>
                        <td className="border-t-0 px-6 align-middle font-bold border-l-0 whitespace-nowrap border-r-0 text-xs p-4">
                          {item?.billing_name}
                        </td>
                        <td className="border-t-0 px-6 align-middle font-bold border-l-0 border-r-0 text-xs p-4">
                          {item?.shipping_address1.split("").slice(0, 40).join("") + "..."}, {item?.shipping_address2.split("").slice(0, 40).join("") + "..."}
                        </td>
                        <td className="border-t-0 px-6 whitespace-nowrap align-middle font-bold border-l-0 border-r-0 text-xs p-4">
                          {item?.shipping_zip}
                        </td>
                        <td className="border-t-0 px-6 whitespace-nowrap align-middle font-bold border-l-0 border-r-0 text-xs p-4">
                          {item?.shipping_city}
                        </td>
                        <td className="border-t-0 px-6 whitespace-nowrap align-middle font-bold border-l-0 border-r-0 text-xs p-4">
                          {item?.shipping_province}
                        </td>
                        <td className="border-t-0 px-6 align-middle font-bold border-l-0 border-r-0 text-xs p-4">
                          {item?.order_status || "N.A."}
                        </td>
                        <td className="border-t-0 px-6 align-middle font-bold border-l-0 border-r-0 text-xs p-4">
                          <button onClick={() => onViewHandle(item?.order_number)} className='px-2 py-1 whitespace-nowrap bg-[#cdf7b3] hover:bg-[#abe28a] rounded shadow-xl'><i
                            className={"fas fa-eye mr-2 text-sm opacity-75 "}
                          ></i>View</button>
                        </td>
                        <td className="sticky right-0 border-t-0 bg-gray-100 align-middle font-bold min-w-28 border-l-0 border-r-0 text-xs py-4 pr-4 pl-2">
                          {item?.is_order_pushed === "0" ? (
                            <div className='border-l-[1.5px] border-gray-400 pl-3'>
                              <button disabled={pushLoader === "dtdc"} onClick={() => handlePush("dtdc", item.order_number, item.id)} className='px-2 py-1 whitespace-nowrap bg-[#1B5C29] hover:bg-[#13471e] rounded shadow text-white'>
                                {(pushLoader === "dtdc" && pushLoaderId === item.id) ? <ClipLoader color="#fff" size={16} speedMultiplier={1} cssOverride={{ borderWidth: "3px", margin: "2px 30px 0 30px" }} /> : "Push To DTDC"}
                              </button>
                              <button disabled={pushLoader === "ecomm"} onClick={() => handlePush("ecomm", item.order_number, item.id)} className='px-2 py-1 whitespace-nowrap bg-[#F4711E] hover:bg-[#e4600c] rounded shadow text-white mt-2'>
                                {(pushLoader === "ecomm" && pushLoaderId === item.id) ? <ClipLoader color="#fff" size={16} speedMultiplier={1} cssOverride={{ borderWidth: "3px", margin: "2px 30px 0 30px" }} /> : "Push To EComm"}
                              </button>
                              <button disabled={pushLoader === "shadowfax"} onClick={() => handlePush("shadowfax", item.order_number, item.id)} className='px-2 py-1 whitespace-nowrap bg-[#619269] hover:bg-[#619269] rounded shadow text-white mt-2'>
                                {(pushLoader === "shadowfax" && pushLoaderId === item.id) ? <ClipLoader color="#fff" size={16} speedMultiplier={1} cssOverride={{ borderWidth: "3px", margin: "2px 30px 0 30px" }} /> : "Push To Shadowfax"}
                              </button>
                            </div>
                          ) : (
                            <div className='border-l-[1.5px] border-gray-400 pl-3'>
                              Pushed to {item?.lm_partner}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <Pagination /> */}
              <div className='flex justify-center my-2'>
                {pageNumber != 1 && (
                  <>
                    <button className='px-0 mr-2 text-xs underline hover:text-sm py-[2px] rounded' onClick={() => onPagination("first")}>First Page</button>
                    <span>...</span>
                  </>
                )}
                <button className='px-2 text-xs py-[2px] hover:text-sm underline  rounded' onClick={() => onPagination("prev")}>Prev</button>
                <div className='flex rounded'>
                  <input type="text" value={pageNumber} onChange={(e) => setPageNumber(e?.target?.value)} className='w-12 h-6 rounded-l ml-2 text-sm px-2 py-1' />
                  <button className='px-2 text-xs py-[2px] hover:text-sm  underline  rounded-r mr-2' onClick={() => onPagination("set")}>Go</button>
                </div>
                {(pageNo !== parseInt(+totalItems / 10)) && (
                  <>
                    <button className='px-2 text-xs py-[2px] hover:text-sm  underline  rounded' onClick={() => onPagination("next")}>Next</button>
                    <span>...</span>
                    <button className='px-2 text-xs py-[2px] hover:text-sm  underline  rounded' onClick={() => onPagination("last")}>Last Page</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-menu m-auto"
        isOpen={openViewModal}
        onRequestClose={() => setOpenViewModal(false)}
        contentLabel="XYZ Component Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
        }}
      >
        <ViewOrderDetailModal closeModal={setOpenViewModal} orderId={orderId} />
      </Modal>
    </>
  )
}

export default OrderListingPage