import React, { Suspense, useEffect, useState } from 'react'
import Loader from '../components/Loader/Loader'
import FooterAdmin from '../components/Footers/FooterAdmin'
import { useNavigate, useParams } from 'react-router-dom'
import { Stepper } from 'react-form-stepper'
import { getOrderTrackingData } from '../services/order'
import { toast } from 'sonner'

const OrderTrackingInfo = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { orderNumber } = params || {};
    const statusFlow = [{ label: "Confirmed" }, { label: "Allocated" }, { label: "Packed" }, { label: "Picked" }, { label: "Shipped Complete" }, { label: "Delivered" }]
    const [orderDetail, setOrderDetail] = useState({});
    const [orderNo, setOrderNo] = useState("")

    // const statusFlow = [
    //     {
    //       label: (
    //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 24 24"
    //             fill="green"
    //             width="24px"
    //             height="24px"
    //           >
    //             <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm-1 17l-5-5 1.41-1.41L11 14.17l6.59-6.58L19 9z" />
    //           </svg>
    //         </div>
    //       ),
    //       title: "Step 1 Complete",
    //     },
    //     {
    //       label: (
    //         <>
    //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 24 24"
    //             fill="orange"
    //             width="24px"
    //             height="24px"
    //           >
    //             <circle cx="12" cy="12" r="10" />
    //           </svg>
    //         </div></>
    //       ),
    //       title: "Step 2 Pending",
    //     },
    //     {
    //       label: (
    //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 24 24"
    //             fill="red"
    //             width="24px"
    //             height="24px"
    //           >
    //             <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm-1 7h2v6h-2zm0 8h2v2h-2z" />
    //           </svg>
    //         </div>
    //       ),
    //       title: "Step 3 Failed",
    //     },
    //   ];


    useEffect(() => {
        (async () => {
            const response = await getOrderTrackingData({ order_number: orderNumber });
            const { data, status } = response || {};
            if (status === 200 || status === 201) {
                setOrderDetail(data?.data);
            }
            if (status === 404){
                toast.error(data?.message)
                setOrderNo("")
                navigate(`/orders/tracking`)
            }
            setOrderNo("")
        })()
    }, [params]);

    const goToOrderTracking = () => {
        if (orderNo === "") {
            toast.error("Enter a order Number");
            return;
        }
        navigate(`/orders/tracking/${orderNo}`)
    }


    return (
        <Suspense fallback={<Loader />}>
            {!orderNumber ? (
                <>
                    <div className="bg-blueGray-100">
                        <nav className="bg-[#02500999] w-full z-10 flex justify-center items-center py-12">
                            <div className='flex gap-3'>
                                <input type="text" className='text-black rounded-lg' onChange={(e) => setOrderNo(e?.target?.value)} />
                                <button className='px-4 py-1 bg-green-200 hover:bg-green-300 rounded-lg' onClick={goToOrderTracking}>Get Tracking Data</button>
                            </div>
                        </nav>
                        <div className="h-[440px]"></div>
                        <FooterAdmin />
                    </div>
                </>
            ) : (
                <div className="relative bg-blueGray-100">
                    <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
                        <div className="w-full md:px-10 text-white px-4 mb-2">
                            {/* Brand */}
                            <a
                                className=" text-sm mt-3 uppercase hidden lg:inline-block font-semibold"
                                href="#pablo"
                            >
                                Your Order Tracking Status
                            </a>
                            <h1 className='mt-2'>Order No: <span className='font-bold'>{orderNumber || "#00"}</span></h1>
                            <h1 className='mt-2'>Order Status: <span className='font-bold'>{orderDetail?.order_status?.toUpperCase() || "--"}</span></h1>
                        </div>
                    </nav>
                    <div className="relative bg-[#02500999] md:pt-32 pt-12 pb-12">

                    </div>  {/* Header */}
                    <div className="px-4 min-h-[450px] md:px-10 mx-auto w-full">
                        <Stepper
                            steps={statusFlow}
                            activeStep={statusFlow.findIndex(status => status?.label?.toLowerCase() === orderDetail?.order_status?.toLowerCase()

                            )}
                            // stepClassName='!w-6'
                            className='p-0'
                            connectorStateColors={true}
                            connectorStyleConfig={{
                                completedColor: "green",
                                disabledColor: orderDetail?.order_status?.toLowerCase() === "cancelled" ? "#a10308" : "gray",
                                size: 4,
                                activeColor: "green"
                            }}
                            styleConfig={{
                                activeBgColor: "green",
                                activeTextColor: "green",
                                inactiveTextColor: "gray",
                                inactiveBgColor: orderDetail?.order_status?.toLowerCase() === "cancelled" ? "#a10308" : "gray",
                                completedTextColor: "green",
                                completedBgColor: 'green',
                                circleFontSize: "0px",
                                size: "1em",
                                labelFontSize: "13px"
                            }}
                            size={20}
                            activeColor={"#000"}
                        />
                    </div>
                    <FooterAdmin />
                </div>
            )}
        </Suspense>
    )
}

export default OrderTrackingInfo